import styled from "styled-components";
import { isMobile} from "react-device-detect";

export const AboutContainer = styled.div`
  display: flex;
  flex: 1 100%;
  margin-top: ${() => (isMobile ? "150px" : "44px")};
  color: #000; 
`;

export const InfoContainer = styled.div`
  position: absolute;
  left:${() => isMobile ? "18px"  : "48px"};
  top: ${() => isMobile ? "81px" : "86px"};
  white-space: nowrap;
  display: flex;
  flex-direction:column;
`;

export const Container = styled.div`
margin-left: ${() => isMobile ? "18px"  : "0px"};
margin-right: ${() => isMobile ? "18px"  : "0px"};
margin-top: ${() => isMobile ? "48px"  : "0px"};
width: ${() => isMobile ? "100%"  : "33%"};
display:flex;
flex-direction: column;
gap: 24px;  


@media screen and (max-width: 620px) {
  margin-left: ${() => isMobile ? "18px"  : "48px"};
  margin-right: ${() => isMobile ? "18px"  : "48px"};
  margin-top: ${() => isMobile ? "48px"  : "150px"};
  width:100%;
}
`;

export const ContainerDesktop = styled.div`
display: ${() => isMobile ? "none"  : "flex"};
margin: 0 auto;
width:16.7%;
flex-direction: column;  
gap: 24px;


@media screen and (max-width: 1205px) {
  width:33%;
  &:nth-of-type(4) {
   display:none;
  }
  &:nth-of-type(5) {
    display:none;
   }
}

@media screen and (max-width: 620px) {
  display: none;
}
`;

export const Instagram = styled.a`
margin-top:12px;
color: #000;
text-decoration: underline;
&:visited{
  color:#000;
}
&:hover {
  color: ${() => (isMobile ? "inherit" : "#5b60fc")};
}
`;

export const Mail = styled.a`
color: #000;
text-decoration: underline;
&:visited{
  color:#000;
}
&:hover {
  color: ${() => (isMobile ? "inherit" : "#5b60fc")};
}
`;


export const DescriptionOLSA = styled.div`
  margin-top: 32px;
  line-height: 1.3;
`;
export const Description = styled.div`
  margin: 0px auto;
  line-height: 1.3;
`;

export const Text = styled.p`
  margin-bottom: 0px;
  
`

export const DescriptionDes = styled.div`
  line-height: 1.3;
  font-size: 13px;
  display: ${() => isMobile ? "block"  : "flex"};
  flex: 1 1 0;
  gap: 24px;
  
`;

export const Designers = styled.div`
  margin-top: ${() => isMobile ? "32px"  : "32px"};
  line-height: 1.3;
  width: ${() => isMobile ? "75%"  : "50%"};
`;

export const Img = styled.img`  
  width:100%;
  height:100%;
  object-fit:cover;

`;