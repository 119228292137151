import React, { useEffect, useState, useRef } from "react";
import SwiperCore, { Navigation, Autoplay, Parallax, EffectFade, Keyboard } from "swiper";
import { SwiperContainer } from "./SwiperElements";
import { Swiper } from "swiper/react";
import { CreateSlides } from "./actions";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/effect-fade/effect-fade.scss";
import "swiper/components/keyboard";
import { isMobile } from "react-device-detect";

// install Swiper components
SwiperCore.use([Navigation, Autoplay, Parallax, EffectFade, Keyboard]);

const SwiperSection = ({
  elements,
  swiper,
  setSwiper,
  slideIndex,
  setSlideIndex,
  isLoading,
  setLoading,
  fontColor,
  setFontColor,
  index,
  setIndex,
  fontColorIndexDesktop,
  fontColorIndexMobile,
  isOpen,
  setOpen,
  setElements,
  setSignal,
  mobile,
  setMobile
}) => {
  // eslint-disable-next-line
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [hidden, setHidden] = useState(0);
  

  const inputRef = useRef();
  


  useEffect(() => {
    addEventListeners();
    return () => removeEventListeners();
  });

  const addEventListeners = () => {
    document.addEventListener("mousemove", onMouseMove);
  };

  const removeEventListeners = () => {
    document.removeEventListener("mousemove", onMouseMove);
  };

  const onMouseMove = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
    if (inputRef.current) {
      let referencePosition = inputRef.current.getBoundingClientRect();
      let middle =
        referencePosition.left +
        (referencePosition.right - referencePosition.left) / 2;

      if (
        e.clientY > referencePosition.top &&
        e.clientY < referencePosition.bottom
      ) {
        if (e.clientX > referencePosition.left && e.clientX < middle) {
          setHidden(2);
        } else if (e.clientX > middle && e.clientX < referencePosition.right) {
          setHidden(2);
        } else {
          setHidden(0);
        }
      } else {
        setHidden(0);
      }
    }
  };

  const vh = useRef(window.innerHeight).current;
  const vhRedux = vh - vh*0.08
  const vhScreen = '' + vhRedux + 'px'
  

  return (
    <SwiperContainer
      fontColor={fontColor}
      cursor={hidden}
      page={elements.swiper}
      load={isLoading}
      ref={inputRef}
      onClick={() => {
        if (hidden === 2) {
          swiper.slideNext(800);
          swiper.autoplay.start();
        } else if (hidden === 1) {
          swiper.slidePrev(800);
          swiper.autoplay.start();
        }
        
      }}
    >
    
      <Swiper
        modules={[Autoplay, EffectFade]}
        slidesPerView={1}
        autoHeight={true}
        loop={true}
        parallax={true}
        updateOnImagesReady={true}
        initialSlide={slideIndex}
        onSwiper={(swiper) => {
          slideIndex = swiper.activeIndex;
          setSwiper(swiper);
        }}
        onImagesReady={(s) => {
          s.autoplay.start();
          setLoading(false);
        }}
        onSlideChange={(s)=>{
          setIndex(s.activeIndex);
          if ((fontColorIndexDesktop.includes(s.activeIndex) && !isMobile) || (fontColorIndexMobile.includes(s.activeIndex) && isMobile)){
            setFontColor("white");
          }
          else {
            setFontColor("black");
          }
        }}
        effect="fade"
        fadeEffect={{crossFade: true}}
        keyboard={false}
        simulateTouch={false}
        speed={800}
        autoplay={{ delay: 2500, disableOnInteraction: false, waitForTransition: true }}
      >
        {CreateSlides(0, 18, fontColor, index, isOpen, setOpen, elements, setElements, setSignal, setMobile, setSlideIndex, mobile, swiper, vhScreen)}
      </Swiper>
    </SwiperContainer>
  );
};

export default SwiperSection;
