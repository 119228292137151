import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { isMobileOnly } from "react-device-detect";

export const Nav = styled.nav`
  color: ${({elements}) => ( elements.about)  ? "#000" : "inherit"};
  position: absolute;
  left: ${() => (isMobile ? "18px" : "48px")};
  top: ${() => (isMobile ? "39px" : "44px")};
  -webkit-tap-highlight-color: transparent;
`;

export const NavbarContainer = styled.div`
  color: inherit; 
  display: inline-grid;
  align-items: center;
  margin-bottom:0px;
`;

export const NavLogoContainer = styled.div`
color: inherit; 
  justify-self: start;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const NavSlide = styled.div`
color: inherit; 
  display: flex;
  justify-content: space-between;
  text-align: center;
  @media screen and (max-width: 575px) {
    display: none;
  } 
  }
`;

export const NavLogoItem = styled.div`
  -webkit-tap-highlight-color: transparent;
  &:hover {
    color: ${() => (isMobile ? "inherit" : "#5b60fc")};
  }
  color: inherit; 
  white-space: nowrap;
  cursor: pointer;
  // margin-right: 25px;
  text-decoration: underline;
  &:visited{
    color:inherit;
  }
  &:link{
    color:inherit;
  }
  // &:hover{
  //   color:${() => (isMobile ? "inherit" : "rgb(120, 120, 120)")};
  // } 

  @media screen and (max-width: 575px) {
    // margin-right: 20px;
  }
`;

export const LogoItem = styled.div`
color: inherit; 
display: ${() => (isMobileOnly ? "none" : "")};
  margin-left: 5px;
  margin-right: 5px;
  white-space: nowrap;
  @media screen and (max-width: 575px) {
    display:none;
  }
`;


export const NavItem = styled.div`
color: inherit; 
  list-style-type: none;
  height: 80px;
  text-decoration: none;
  height: 100%;
  @media screen and (max-width: 1200px) {
    visibility: hidden;
  }
`;



