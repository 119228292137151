import React from "react";
import "swiper/components/navigation/navigation.scss";
import { Description, AboutContainer, ContainerDesktop, InfoContainer, Img, Container, DescriptionDes, DescriptionOLSA, Designers, Instagram, Mail , Text} from "./AboutElements";

const AboutSection = ({
  elements
}) => {
  const handleClick = event => {
    event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
  }
  return (
    <AboutContainer elements={elements}> 
      <InfoContainer>
        <Mail href="mailto:studio@olsa.design">
          Email us
        </Mail>
        <Instagram href="https://www.instagram.com/olsa_design" target="_blank">
          Instagram
        </Instagram>
      </InfoContainer>
      <ContainerDesktop></ContainerDesktop>
      <Container>
        <Description>
          <Img src={require(`../../assets/images/_team/olsa_team_web.jpg`).default}  alt="olsa_team_web"/>
        </Description>
        <Description>
          <DescriptionOLSA>
            <p>Olsa is a design studio founded by André and Vera, based in Portugal and working internationally in all areas of visual communication. 
            Olsa’s approach is a good research and structure that leads to a practical and effective results.</p>
            <p>The studio offers services in corporate identity, branding, editorial, digital interface, packaging, signage, exhibition graphics, consulting and more.
            The result is useful and lasting work that enhances daily experiences.</p>
            <Text>In addition to running their studio, they teach at the School of Arts and Design (ESAD.CR) and are committed to fostering creativity and knowledge in students.</Text>
          </DescriptionOLSA>
        </Description>
        <Description>
          <DescriptionDes>
            <Designers>
              — <br />
              André Oliveira <br />
              Co-founder & Creative Director <br />
              <p>André studied graphic design at the Escola Superior de Artes e Design (ESAD.CR) in Caldas da Rainha, where he received a Bachelor's degree in Communication Design.</p>
              <p>Early on, he began collaborating with internationally renowned studios such as Grafisches Büro and Studio Andrew Howard, and more recently with designer Sean Wolcott at Rationale. Following his time at Rationale, he joined the San Francisco offices of Eat Just, working under creative director Jordan Viola.</p>
              <p>In addition to his professional practice, André teaches at the Escola Superior de Artes e Design (ESAD.CR) in Caldas da Rainha, where he teaches Typography and Introduction to Branding.</p>
            </Designers>
            <Designers>
              — <br />
              Vera Oliveira <br />
              Co-founder & Lead Designer <br />
              <p>Vera began her career as an industrial designer, becoming an integral part of SAL Architects' team. 
                Her contributions ranged from the creation of scale models, collaboration on interior design projects, 
                playing a key role in the creation of the studio's logo, and involvement in graphic communications.</p>
              <p>In 2020, she started her MA in UI/UX Design. She received a scientific research grant to participate in a two-year project focused on developing a patient monitoring system for the Emergency Department (ED) of a Portuguese hospital. 
                Working closely with healthcare professionals and patient representatives, 
                Vera applied user-centered methodologies, conducted on-site observations, and facilitated workshops for data collection and usability testing. 
                The primary goal was to deeply understand the current situation and redesign the department's future patient monitoring system. 
                Her dedicated efforts resulted in the creation of a high-fidelity user interface prototype that prioritized intuitiveness, efficiency, and aesthetics, ultimately improving the overall user experience.</p>
              <p>She was awarded a master's degree with honors by the school's academy upon successful completion of her research project and studies.</p>
              <p>With her diverse education and extensive experience, Vera is the co-founder of Olsa with André in 2023. 
                Olsa is a design studio for visual communication, based in Portugal, that serves both national and international clients.</p>
            </Designers>
          </DescriptionDes>
        </Description>
      </Container>
      <ContainerDesktop></ContainerDesktop>
      <ContainerDesktop></ContainerDesktop>
      <ContainerDesktop></ContainerDesktop>
      
    </AboutContainer>
  );
};

export default AboutSection;
