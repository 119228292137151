export const subtitles = [
  "Arcade Magazine",
  "Seattle, 2019",
  "Arcade Magazine",
  "Seattle, 2019",
  "Monastery Records",
  "Seattle, 2018",
  "Tools for the Design Revolution",
  "Austria, 2014",
  "Minimal Calendar app",
  "Seattle, 2018",
  "Festival Plaster",
  "Poland, 2015",
  "Rope-a-dope Bryan Locke",
  "California, 2017",
  "SynthPatch app",
  "Seattle, 2017",
  "Braun systems",
  "London, 2013",
  "Annual Activity Lifestyle Poster",
  "Portugal, 2020",
  "JUST Egg",
  "California, 2022",
  "JUST Egg",
  "California, 2022",
  "ScapeW",
  "Brazil, 2023",
  "Super Fantastics",
  "California, 2020",
  "Super Fantastics",
  "California, 2020",
  "Casa 29",
  "Portugal, 2023",
  "O Portugal de Emílio Biel",
  "Portugal, 2015",
  "StartupCPG",
  "California, 2020",
];
