import styled from "styled-components";
import {isMobile} from "react-device-detect";

export const SwiperContainer = styled.div`
  height:100vh;
  width: 100vw;
  cursor: pointer;
`;

export const ImgWrap = styled.div`
  height:100vh;
  width: 100vw;
`;

export const Img = styled.img`  
  width:100%;
  height:100%;
  object-fit:cover;

`;

export const Video = styled.video`  
  width:100%;
  height:100%;
  object-fit:cover;
`;

export const ImgSubtitle = styled.div`
  // position: ${() => isMobile ? " absolute"  : "inherit"};
  left:${() => isMobile ? "18px"  : "inherit"};
  top: ${() => isMobile ? "83px" : "inherit"};
  white-space: nowrap;
  // &:hover {
  //   color: ${() => (isMobile ? "inherit" : "#5b60fc")};
  // }
`;

export const SubtitleTop = styled.div`
`;

export const SubtitleBottom = styled.div`
  margin-top: 6px;
`;


export const SubContainer = styled.div`
  position: absolute;
  left: ${() => (isMobile ? "18px" : "0px")};
  top: ${() => (isMobile ? "81px" : "0px")};
  width: 100%;
  display: flex;
  flex: 1 100%;
  margin-top: ${() => (isMobile ? "0px" : "44px")};
  @media screen and (max-width: 575px) {
    left: ${() => (isMobile ? "18px" : "48px")};
    top: 81px;
    margin-top:0px;
  }
`;

export const Container = styled.div`
// margin-left: ${() => isMobile ? "18px"  : "0px"};
// margin-right: ${() => isMobile ? "18px"  : "0px"};
// margin-top: ${() => isMobile ? "83px"  : "0px"};
// margin-left: 0px;
margin-right: 0px;
margin-top: 0px;
width: ${() => isMobile ? "100%"  : "16.6%"};
display:flex;
flex-direction: column;
gap: 24px; 
@media screen and (max-width: 575px) {
  width:100%;

} 
`;

export const ContainerDesktop = styled.div`
display: ${() => isMobile ? "none"  : "flex"};
margin: 0 auto;
width:16.7%;
flex-direction: column;  
gap: 24px;
display:  ${() => (isMobile ? "none" : "inherit")};

@media screen and (max-width: 1020px) {
  width:33%;
}

@media screen and (max-width: 620px) {
  width:66%;
}

@media screen and (max-width: 575px) {
  display:none;
}
`;

export const ContainerRest = styled.div`
display: ${() => isMobile ? "none"  : "flex"};
margin: 0 auto;
width:67%;
flex-direction: column;  
gap: 24px;
display:  ${() => (isMobile ? "none" : "inherit")};
@media screen and (max-width: 575px) {
  display:none;
}
`;