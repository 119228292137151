import {React} from "react";
import { SwiperSlide } from "swiper/react";
import { ImgWrap, Img, ImgSubtitle, SubtitleTop, SubtitleBottom, SubContainer, ContainerDesktop, Container, ContainerRest } from "./SwiperElements";
import { isMobileOnly } from "react-device-detect";
import "react-lazy-load-image-component/src/effects/blur.css";
import { subtitles } from "./Data.js";

export function CreateSlides(start, numberOfSlides, fontColor, index, isOpen, setOpen, elements, setElements,setSignal, setMobile, setSlideIndex, mobile, swiper, vhScreen) {
  const slideImages = [];
  if (isMobileOnly) {
    for (let i = start; i < numberOfSlides; i += 1) {
      slideImages[i] = {
        id: "slide" + (i + 1),
        img: require(`../../assets/images/mobile/img_${1 + i}.jpg`)
          .default,
        alt: "slide" + (i + 1),
      };
  }
  } else {
    for (let i = start; i < numberOfSlides; i += 1) {
        slideImages[i] = {
          id: "slide" + (i + 1),
          img: require(`../../assets/images/desktop/img_${1 + i}.jpg`).default,
          alt: "slide" + (i + 1),
        };
    }
  }
  const slides = [];
  for (let i = start; i < numberOfSlides; i += 1) {
      slides.push(
        <SwiperSlide  key={slideImages[i].id}>
          <ImgWrap>
            <Img src={slideImages[i].img}  alt={slideImages[i].alt}/>
          </ImgWrap>
          <SubContainer>
            <ContainerDesktop></ContainerDesktop>
            <Container>
            <ImgSubtitle 
            index={index} 
            fontColor={fontColor} 
            vhScreen={vhScreen} 
            className="subtitle"
            onClick={() => {
              if (!isOpen){
                setOpen(true);
              }
              else{
                setOpen(false);
              }
              let index = 0;
              if (index === elements.index) {
                index = 1;
              }
              setElements({
                swiper: !elements.swiper,
                about: !elements.about,
                index: index,
              });
              setMobile(!mobile);
              mobile ? setSignal(1) : setSignal(2);
              if (elements.swiper) {
                setSlideIndex(swiper.activeIndex);
              }
            }
          }>
            <SubtitleTop>
              {subtitles[i*2]}
            </SubtitleTop>
            <SubtitleBottom>{subtitles[i*2+1]}</SubtitleBottom>
          </ImgSubtitle>
            </Container>
            <ContainerRest></ContainerRest>
          </SubContainer>
        </SwiperSlide>
      );
  }
  return slides;
}
