import styled from "styled-components";
import { isMobile } from "react-device-detect";

export const HeroContainer = styled.div`
  position: absolute;
  z-index: -1;
  left: ${() => (isMobile ? "50%" : "0vw")};
  margin-left: ${() => (isMobile ? "-50%" : "inherit")};
  top: ${() => (isMobile ? "0vh" : "0vh")}; 
  align-items: center;
`;

export const HeroActive = styled.div``;
