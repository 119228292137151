import styled from "styled-components";
import { isMobile } from "react-device-detect";

export const Section = styled.div`
  visibility: ${({ load }) => (load ? "hidden" : "visible")};
  opacity: ${({ load }) => (load ? "0" : "1")};
  transition: visibility 1s, opacity 1s;
  color: ${({fontColor }) => fontColor==="white" ? "#ffffff" : "#000000"};
  `

export const Header = styled.div`
border-top: solid 1px;
margin-top: ${() => (isMobile ? "26px" : "28px")};
margin-left: ${() => (isMobile ? "11px" : "40px")};
margin-right: ${() => (isMobile ? "11px" : "40px")};
color: ${({fontColor, elements}) => elements.about ? "#000" : fontColor==="white"  ? "#ffffff" : "#000000"};
`