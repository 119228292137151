import React, { useState} from "react";
import { isMobile } from "react-device-detect";
import {
  Nav,
  NavLogoItem,
} from "./NavbarElements";

const Navbar = ({
  mobile,
  setMobile,
  elements,
  setElements,
  setSlideIndex,
  swiper,
  setSignal,
  fontColor,
  index,
  isOpen,
  setOpen
}) => {
  

  return (
    <>
      <Nav fontColor={fontColor} elements={elements} index={index}>
        <NavLogoItem
          id="aboutLink"
          mobile={mobile}
          page={elements.about}
          fontColor={fontColor}
          elements={elements}
          index={index}
          onClick={() => {
              if (!isOpen){
                setOpen(true);
              }
              else{
                setOpen(false);
              }
              let index = 0;
              if (index === elements.index) {
                index = 1;
              }
              setElements({
                swiper: !elements.swiper,
                about: !elements.about,
                index: index,
              });
              setMobile(!mobile);
              mobile ? setSignal(1) : setSignal(2);
              if (elements.swiper) {
                setSlideIndex(swiper.activeIndex);
              }
            }
          }
        >
          olsa.design
        </NavLogoItem>        
      </Nav>
    </>
  );
};

export default Navbar;
